<template>
    <div>
        <div v-if="condoTown" style="height:100%; overflow-x: scroll;" class="hide-scrollbar">
            <draggable class="dF aE" style="width:100%;" :list="galleryImages" :delay-on-touch-only="true" :delay="100">
                <div v-for="(image, imageI) in galleryImages" :key="imageI" class="mr-4">
                    <div v-if="imageI == 0">Main Floor Plan</div>
                    <div v-else>Level {{imageI+1}} Floor Plan</div>
                    <div class="mt-2" style="height:120px; width:120px;">
                        <div class="relative previewImage" style="height:100%; width:100%">
                            <div class="absolute zoomButton" style="height:100%; width:100%; background-color:rgba(64, 69, 76, 0.5);">
                                <div class="image-overlay dF aC jC">
                                    <div class="absolute w-full h-full" style="z-index: 1000;" @click="previewImage(image)"></div>
                                    <a-button-group style="z-index: 1001;">
                                        <!-- <a-button>Image</a-button> -->
                                        <a-button @click="$store.commit('MEDIA_SELECT',{callback:addImage,type:'images'})"><a-icon type="edit" /></a-button>
                                        <a-button v-if="!isMarketing" style="color:#FD647C" @click="deleteImage(imageI)"><a-icon type="delete" /></a-button>
                                    </a-button-group>
                                </div>
                            </div>
                            <img style="width:100%; height:100%; object-fit:cover" :src="image.url" />
                        </div>
                    </div>
                </div>
                <div v-if="galleryImages.length < storey" style="width:120px; height:120px; border-radius:4px; cursor:pointer">
                    <div @click="$store.commit('MEDIA_SELECT',{callback:addImage,type:'images'})" class="dF aC jC" style="width:120px; height:120px; background-color:#FAF9F9; border:dashed 1px #D0C9D6; color:#FD9026; border-radius:4px">
                        <div>Add Image</div>
                    </div>
                </div>
            </draggable>
        </div>


        <div v-else class="dF aE">
            <div v-for="(image, imageI) in galleryImages" :key="imageI" class="mr-10">
                <div v-if="imageI == 0">Main Floor Plan <span v-if="!isMarketing" style="color: var(--danger);">*</span></div>
                <div class="mt-2" style="height:120px; width:120px;">
                    <div class="relative previewImage" style="height:100%; width:100%">
                        <div class="absolute zoomButton" style="height:100%; width:100%; background-color:rgba(64, 69, 76, 0.5);">
                            <div class="image-overlay dF aC jC">
                                <div class="absolute w-full h-full" style="z-index: 1000;" @click="previewImage(image)"></div>
                                <a-button-group style="z-index: 1001;">
                                    <!-- <a-button>Image</a-button> -->
                                    <a-button @click="$store.commit('MEDIA_SELECT',{callback:replaceImage,type:'images'})"><a-icon type="edit" /></a-button>
                                    <a-button v-if="!isMarketing" style="color:#FD647C" @click="deleteImage(imageI)"><a-icon type="delete" /></a-button>
                                </a-button-group>
                            </div>
                        </div>
                        <img style="width:100%; height:100%; object-fit:cover" :src="image.url" />
                    </div>
                </div>
            </div>
            <div class="mr-10" v-if="galleryImages.length < 1">
                <div>Main Floor Plan <span v-if="!isMarketing" style="color: var(--danger);">*</span></div>
                <div class="mt-2" style="width:120px; height:120px; border-radius:4px; cursor:pointer">
                    <div @click="$store.commit('MEDIA_SELECT',{callback:addImage,type:'images'})" class="dF aC jC" style="width:120px; height:120px; background-color:#FAF9F9; border:dashed 1px #D0C9D6; color:#FD9026; border-radius:4px">
                        <div>Add Image</div>
                    </div>
                </div>
            </div>
            <div class="mr-8">
                <a-checkbox v-model="tempHasPenthouse" @change="updateHasPenthouse" :disabled="isMarketing">Loft/Second Level</a-checkbox>
                <div v-if="penthouseImage" class="mt-2" style="height:120px; width:120px;">
                    <div class="relative previewImage" style="height:100%; width:100%">
                        <div class="absolute zoomButton" style="height:100%; width:100%; background-color:rgba(64, 69, 76, 0.5);">
                            <div class="image-overlay dF aC jC">
                                <div class="absolute w-full h-full" style="z-index: 1000;" @click="previewImage(image)"></div>
                                <a-button-group style="z-index: 1001;">
                                    <!-- <a-button>Image</a-button> -->
                                    <a-button @click="$store.commit('MEDIA_SELECT',{callback:addLoft,type:'images'})"><a-icon type="edit" /></a-button>
                                    <a-button v-if="!isMarketing" style="color:#FD647C" @click="deleteLoft"><a-icon type="delete" /></a-button>
                                </a-button-group>
                            </div>
                        </div>
                        <img style="width:100%; height:100%; object-fit:cover" :src="penthouseImage" />
                    </div>
                </div>
                <div v-else class="mt-2" style="width:120px; height:120px; border-radius:4px; cursor:pointer">
                    <div @click="$store.commit('MEDIA_SELECT',{callback:addLoft,type:'images'})" class="dF aC jC" style="width:120px; height:120px; background-color:#FAF9F9; border:dashed 1px #D0C9D6; color:#FD9026; border-radius:4px">
                        <div>Add Image</div>
                    </div>
                </div>
            </div>
            <div>
                <a-checkbox v-model="tempHasTerrace" @change="updateHasTerrace" :disabled="isMarketing">Terrace</a-checkbox>
                <div v-if="terraceImage" class="mt-2" style="height:120px; width:120px;">
                    <div class="relative previewImage" style="height:100%; width:100%">
                        <div class="absolute zoomButton" style="height:100%; width:100%; background-color:rgba(64, 69, 76, 0.5);">
                            <div class="image-overlay dF aC jC">
                                <div class="absolute w-full h-full" style="z-index: 1000;" @click="previewImage(image)"></div>
                                <a-button-group style="z-index: 1001;">
                                    <!-- <a-button>Image</a-button> -->
                                    <a-button @click="$store.commit('MEDIA_SELECT',{callback:addTerrace,type:'images'})"><a-icon type="edit" /></a-button>
                                    <a-button v-if="!isMarketing" style="color:#FD647C" @click="deleteTerrace"><a-icon type="delete" /></a-button>
                                </a-button-group>
                            </div>
                        </div>
                        <img style="width:100%; height:100%; object-fit:cover" :src="terraceImage" />
                    </div>
                </div>
                <div v-else class="mt-2" style="width:120px; height:120px; border-radius:4px; cursor:pointer">
                    <div @click="$store.commit('MEDIA_SELECT',{callback:addTerrace,type:'images'})" class="dF aC jC" style="width:120px; height:120px; background-color:#FAF9F9; border:dashed 1px #D0C9D6; color:#FD9026; border-radius:4px">
                        <div>Add Image</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components:{
        draggable
    },
    props:{
		isMarketing:{
			default:false,
			type:Boolean
		},
        galleryImages:{
            default:() => [],
            type:Array
        },
        storey:{
            default:1,
            type:Number
        },
        hasTerrace:{
            default:false,
            type:Boolean
        },
        terraceImage:{
            default:'',
            type:String
        },
        hasPenthouse:{
            default:false,
            type:Boolean
        },
        penthouseImage:{
            default:'',
            type:String
        },
        condoTown:{
            default:false,
            type:Boolean
        }
    },
    watch:{
        galleryImages:{
            handler(val) {
                console.log('changeeeee', this.galleryImages)
				if (this.isMarketing){
					this.$emit('marketingImages', this.galleryImages)
				} else {
					this.$emit('galleryImages' , this.galleryImages)
				}
            }
        },
        hasTerrace:{
            handler(val) {
                this.tempHasTerrace = val
            }
        },
        hasPenthouse:{
            handler(val) {
                this.tempHasPenthouse = val
            }
        }
    },
    data() {
        return {
            selectedImages:[],
            tempHasTerrace:false,
            tempHasPenthouse:false
            // galleryImages:[]
        }
    },
    methods:{
        updateHasTerrace(val) {
            this.$emit('hasTerrace' , val.target.checked)
        },
        updateHasPenthouse(val) {
            this.$emit('hasPenthouse' , val.target.checked)
        },
        previewImage(data){
            this.$emit("previewGallery" ,data)
        },
        addImage(data){
            let image = {
                url:'',
                id:'',
            }
            if (typeof(data) == 'object') {
                image.url = data.url
                image.id = Date.now()
                this.galleryImages.push(image)
            } else {
                image.url = data
                image.id = Date.now()
                this.galleryImages.push(image)
            }
        },
		replaceImage(data){
            let image = {
                url:'',
                id:'',
            }
            if (typeof(data) == 'object') {
                image.url = data.url
                image.id = Date.now()
				this.galleryImages.splice(0,1)
				this.galleryImages.push(image)
            } else {
                image.url = data
                image.id = Date.now()
				this.galleryImages.splice(0,1)
                this.galleryImages.push(image)
            }
        },
        addTerrace(data){
            if (typeof(data) == 'object') {
                this.$emit('terraceImage' , data.url)
            } else {
                this.$emit('terraceImage' , data)
            }
            this.$emit('hasTerrace' , true)
        },
        deleteTerrace(){
            this.$emit('terraceImage' , '')
        },
        addLoft(data){
            if (typeof(data) == 'object') {
                this.$emit('penthouseImage' , data.url)
            } else {
                this.$emit('penthouseImage' , data)
            }
            this.$emit('hasPenthouse' , true)
        },
        deleteLoft(){
            this.$emit('penthouseImage' , '')
        },
        bulkSelectImage(data) {
            if (this.selectedImages.includes(data)) {
                let index = this.selectedImages.findIndex(x => x.id == data.id)
                this.selectedImages.splice(index,1)
            } else {
                this.selectedImages.push(data)
            }
            console.log('selectedImages', this.selectedImages)
        },
        deleteImage(index){
            // this.selectedImages.forEach(sel => {
            //     let index = this.galleryImages.findIndex(gal => gal.id == sel.id)
            //     this.galleryImages.splice(index, 1)
            // })
            // this.selectedImages = []
            this.galleryImages.splice(index,1)
        }
    },
    mounted(){
        this.tempHasTerrace = this.hasTerrace
        this.tempHasPenthouse = this.hasPenthouse
    }

}
</script>

<style scoped>
.zoomButton {
    opacity:0;
}
.previewImage:not(:hover) .zoomButton{
    opacity:0;
    transform: opacity 0.3s ease-in;
}
.previewImage:hover .zoomButton{
    opacity:1;
    border: 1px solid var(--orange);
    transform: opacity 0.3s ease-in;
}
.image-overlay {
	position: relative;
	z-index: 1000;
	width: 100%;
    height:100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
</style>
